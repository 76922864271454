export const ICONS = {
  ACCOUNT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 6.25C7.5 8.73528 9.51472 10.75 12 10.75C14.4853 10.75 16.5 8.73528 16.5 6.25C16.5 3.76472 14.4853 1.75 12 1.75C9.51472 1.75 7.5 3.76472 7.5 6.25H7.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 22.25C21.3 17.2697 16.8262 13.7534 12 13.7534C7.17381 13.7534 2.7 17.2697 2 22.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ALERTSOFF: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12 3.60756V1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39551 12.7916C5.45894 12.0492 5.49722 11.2029 5.49722 10.2416C5.49722 6.57763 8.40803 3.60742 11.9987 3.60742C12.9184 3.60742 13.7934 3.80227 14.5864 4.15372"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 17.5391H19.8011C19.8011 17.5391 18.5008 16.4741 18.5008 10.2415C18.5008 8.88919 18.1042 7.63137 17.4233 6.58252"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2656 20.9502C10.4917 21.7207 11.1986 22.2499 12.0015 22.2499C12.8045 22.2499 13.5113 21.7207 13.7374 20.9502"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8428 3.98389L4.04492 18.8525"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  ALERTSON: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12 3.60756V1.75"
        stroke="#4170C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.60742C15.5907 3.60742 18.5015 6.57764 18.5015 10.2416C18.5015 16.4742 19.8018 17.5392 19.8018 17.5392H4.19824C4.19824 17.5392 5.49854 15.8443 5.49854 10.2416C5.49854 6.57764 8.40935 3.60742 12 3.60742Z"
        fill="#4170C4"
        stroke="#4170C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.43105 2.7251C3.75111 4.04289 2.91263 5.69692 2.50195 7.60374"
        stroke="#4170C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5699 2.7251C20.2499 4.04289 21.0883 5.69692 21.499 7.60374"
        stroke="#4170C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2666 20.9502C10.4927 21.7207 11.1995 22.2499 12.0025 22.2499C12.8054 22.2499 13.5123 21.7207 13.7384 20.9502H10.2666Z"
        fill="#4170C4"
        stroke="#4170C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ARROWDOWN: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M19.2193 14.7095L12.5149 21.0453C12.2258 21.3185 11.7737 21.3185 11.4847 21.0453L4.78027 14.7095"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.75V21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ARROWLEFT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M9.29076 4.78027L2.9549 11.4846C2.6817 11.7737 2.6817 12.2258 2.9549 12.5149L9.29076 19.2193"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 12L2.75 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ARROWRIGHT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M14.707 4.78027L21.0429 11.4846C21.3161 11.7737 21.3161 12.2258 21.0429 12.5149L14.707 19.2193"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 12L2.75 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ARROWUP: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M19.2193 9.29076L12.5149 2.9549C12.2258 2.6817 11.7737 2.6817 11.4847 2.9549L4.78027 9.29076"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.25V2.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  BURGER: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M3 12H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CALENDAR: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M2.75 5.25C2.75 4.83579 3.08579 4.5 3.5 4.5H20.5C20.9142 4.5 21.25 4.83579 21.25 5.25V20.5C21.25 20.9142 20.9142 21.25 20.5 21.25H3.5C3.08579 21.25 2.75 20.9142 2.75 20.5V5.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7.25 2.75V6.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 2.75V6.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 9.25H21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CALENDARTIME: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 12.25C20.0117 12.25 22.25 14.4883 22.25 17.25C22.25 20.0117 20.0117 22.25 17.25 22.25C14.4883 22.25 12.25 20.0117 12.25 17.25C12.25 14.4883 14.4883 12.25 17.25 12.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 17.25H17.25V15.251"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99548 17.5H2.5C2.08579 17.5 1.75 17.1642 1.75 16.75V4.25C1.75 3.83579 2.08579 3.5 2.5 3.5H18.75C19.1642 3.5 19.5 3.83579 19.5 4.25V10.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 1.75V5.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 8.25H19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 1.75V5.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CAMERA: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M1.75 8.5V18.25C1.75 19.0784 2.42157 19.75 3.25 19.75H20.75C21.5784 19.75 22.25 19.0784 22.25 18.25V8.5C22.25 7.67157 21.5784 7 20.75 7H18.1636C17.7409 7 17.3379 6.82172 17.0536 6.50901L15.4464 4.74099C15.1621 4.42828 14.7591 4.25 14.3364 4.25H9.66355C9.24094 4.25 8.83793 4.42828 8.55364 4.74099L6.94636 6.50901C6.66207 6.82172 6.25906 7 5.83645 7H3.25C2.42157 7 1.75 7.67157 1.75 8.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="12.5"
        r="3.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  CHECKMARK: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M2.75 12.7128L6.9659 18.3955C7.51999 19.1817 8.64194 19.3925 9.46963 18.8662C9.63482 18.76 9.78281 18.6292 9.90327 18.4789L21.25 4.84375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CHEVRONDOWN: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M1.75 7.75L11.4615 17.7741C11.7561 18.0783 12.2399 18.0825 12.5346 17.7784L22.2547 7.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CHEVRONLEFT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M16.2544 1.75L6.23024 11.4615C5.9261 11.7561 5.92187 12.2399 6.22593 12.5346L16.2544 22.2547"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CHEVRONRIGHT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M7.75 1.75L17.7741 11.4615C18.0783 11.7561 18.0825 12.2399 17.7784 12.5346L7.75 22.2547"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CHEVRONUP: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M1.75 18L11.4615 7.97585C11.7561 7.67171 12.2399 7.66747 12.5346 7.97154L22.2547 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CLOSE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M2.75 2.75L21.25 21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 21.25L21.25 2.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CUBE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M2.75 16.7313V7.26848C2.75 6.65359 3.12527 6.10098 3.69681 5.87421L11.1702 2.90909C11.7032 2.69762 12.2968 2.69762 12.8298 2.90909L20.3032 5.87421C20.8747 6.10098 21.25 6.65359 21.25 7.26848V16.7313C21.25 17.3461 20.8747 17.8988 20.3032 18.1255L12.8298 21.0906C12.2968 21.3021 11.7032 21.3021 11.1702 21.0906L3.69681 18.1255C3.12527 17.8988 2.75 17.3461 2.75 16.7313Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9998 9.82375L3.51367 6.49658"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.82353L20.4893 6.49512"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.25V9.82373"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  DIAGONALARROW: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M9.75 2.75H20.5C20.9142 2.75 21.25 3.08579 21.25 3.5V14.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 21.25L20.9985 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  DRAW: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2351 4.92354L19.0765 8.76501"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4061 3.59386C21.5313 4.719 21.5313 6.54321 20.4061 7.66835L7.81656 20.2579L2.75 21.25L3.74207 16.1834L16.3316 3.59386C17.4568 2.46871 19.281 2.46871 20.4061 3.59386Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ),
  DRAW_ACTIVE: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.8125 18.25C3.26549 18.25 2.74089 18.4607 2.35409 18.8358C1.9673 19.2109 1.75 19.7196 1.75 20.25C1.75 20.7804 1.9673 21.2891 2.35409 21.6642C2.74089 22.0393 3.26549 22.25 3.8125 22.25H18.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.373 4.40915L19.5915 7.62768"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.543 2.45701C22.4857 3.3997 22.4857 4.92809 21.543 5.87078L10.995 16.4188L6.75 17.25L7.58119 13.005L18.1292 2.45701C19.0719 1.51433 20.6003 1.51433 21.543 2.45701Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ),
  EMAIL: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M1.74707 18.5V5.5C1.74707 5.08579 2.08286 4.75 2.49707 4.75H21.4996C21.9138 4.75 22.2496 5.08579 22.2496 5.5V18.5C22.2496 18.9142 21.9138 19.25 21.4996 19.25H2.49707C2.08286 19.25 1.74707 18.9142 1.74707 18.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.75 5.25L13.9254 11.7986C12.8112 12.7311 11.1888 12.7311 10.0746 11.7986L2.25 5.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ERRORCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 15.75L15.75 8.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 15.75L8.25 8.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  FACEBOOK: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M17.5832 8.39125H13.7036V6.3946C13.6909 6.26758 13.7049 6.13929 13.7449 6.01806C13.785 5.89684 13.85 5.78537 13.9359 5.69091C14.0218 5.59645 14.1265 5.5211 14.2434 5.46975C14.3603 5.41839 14.4867 5.39219 14.6143 5.39283H17.3464V1.75H13.4031C9.82401 1.75 9.15009 4.48212 9.15009 6.17603V8.39125H6.41797V12.0341H9.15009V22.25H13.7036V12.0341H17.2098L17.5832 8.39125Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  FILTERS: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12 10.75V21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.75V6.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="19"
        cy="15.7617"
        rx="2"
        ry="2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="12"
        cy="8.75"
        rx="2"
        ry="2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 2.75V13.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 18V21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="5"
        cy="15.7617"
        rx="2"
        ry="2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 2.75V13.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 18V21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  FLAG: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M17.9933 3.5H5.99512V13.5H17.9933C18.6758 13.5 19.0035 12.6624 18.5023 12.1992L15.0957 9.05079C14.7744 8.75387 14.7744 8.24613 15.0957 7.94921L18.5023 4.80079C19.0035 4.33755 18.6758 3.5 17.9933 3.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 1.75V22.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  FLOORPLAN: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M2.74805 4.2583C2.74805 3.42987 3.41962 2.7583 4.24805 2.7583H19.7499C20.5783 2.7583 21.2499 3.42987 21.2499 4.2583V19.7601C21.2499 20.5886 20.5783 21.2601 19.7499 21.2601H4.24805C3.41962 21.2601 2.74805 20.5886 2.74805 19.7601V4.2583Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M3 17.5H8.5V21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 15.5H21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 3V12.25H3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 3V21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  HEART: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9518 5.56642C20.135 4.72583 19.0029 4.25013 17.819 4.25013C16.6352 4.25013 15.5031 4.72583 14.6863 5.56642L12 8.29422L9.31553 5.56642C8.49842 4.72573 7.36597 4.25 6.18186 4.25C4.99774 4.25 3.8653 4.72573 3.04819 5.56642C1.31727 7.34493 1.31727 10.144 3.04819 11.9225L11.0464 20.0342C11.2952 20.2897 11.6398 20.4342 12 20.4342C12.3602 20.4342 12.7048 20.2897 12.9536 20.0342L20.9518 11.9225C22.6827 10.144 22.6827 7.34493 20.9518 5.56642Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  HEARTFILLED: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9518 5.56642C20.135 4.72583 19.0029 4.25013 17.819 4.25013C16.6352 4.25013 15.5031 4.72583 14.6863 5.56642L12 8.29422L9.31553 5.56642C8.49842 4.72573 7.36597 4.25 6.18186 4.25C4.99774 4.25 3.86529 4.72573 3.04819 5.56642C1.31727 7.34493 1.31727 10.144 3.04819 11.9225L11.0464 20.0342C11.2952 20.2897 11.6398 20.4342 12 20.4342C12.3602 20.4342 12.7048 20.2897 12.9536 20.0342L20.9518 11.9225C22.6827 10.144 22.6827 7.34493 20.9518 5.56642Z"
        fill="#4170C4"
        stroke="#4170C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  HELPCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 16.9121C11.8382 16.9121 11.707 17.0433 11.707 17.2049C11.707 17.3666 11.8382 17.4978 11.9999 17.4978C12.1615 17.4978 12.2927 17.3666 12.2927 17.2049C12.2927 17.0433 12.1615 16.9121 11.9999 16.9121V16.9121"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2666 9.1373C9.2667 8.63618 9.40454 8.14473 9.66508 7.71666C9.92561 7.28859 10.2988 6.94037 10.7439 6.71007C11.1889 6.47977 11.6887 6.37624 12.1887 6.41081C12.6886 6.44537 13.1694 6.6167 13.5785 6.90606C13.9877 7.19542 14.3094 7.59168 14.5085 8.05153C14.7077 8.51138 14.7766 9.01713 14.7077 9.5135C14.6388 10.0099 14.4348 10.4777 14.118 10.866C13.8012 11.2543 13.3838 11.548 12.9113 11.715C12.6447 11.8093 12.4139 11.9839 12.2507 12.2148C12.0875 12.4457 12 12.7216 12.0001 13.0043V13.921"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  IMAGE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M3.5 20.25H20.5C20.9142 20.25 21.25 19.9142 21.25 19.5V8.12132C21.25 7.7235 21.092 7.34196 20.8107 7.06066L17.9393 4.18934C17.658 3.90804 17.2765 3.75 16.8787 3.75H3.5C3.08579 3.75 2.75 4.08579 2.75 4.5V19.5C2.75 19.9142 3.08579 20.25 3.5 20.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 17.2499L7.88652 14.3365C8.18871 13.9244 8.80551 13.9286 9.10209 14.3447L9.82592 15.3603C10.1212 15.7747 10.7347 15.781 11.0384 15.3727L13.3219 12.3038C13.6207 11.9021 14.2216 11.9004 14.5227 12.3003L18.25 17.2499"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  INFORMATIONCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5116 7.70898C11.35 7.70898 11.2188 7.84018 11.2188 8.00184C11.2188 8.1635 11.35 8.2947 11.5116 8.2947C11.6733 8.2947 11.8045 8.1635 11.8045 8.00184C11.8045 7.84018 11.6733 7.70898 11.5116 7.70898V7.70898"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4995 11.291V14.791C11.4995 15.6194 12.1711 16.291 12.9995 16.291H13.9999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  LIGHTBULB: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12 6.25C8.82436 6.25 6.25 8.82436 6.25 12C6.25 14.6114 7.99078 16.8162 10.3752 17.5172V19.25H13.6248V17.5172C16.0092 16.8162 17.75 14.6114 17.75 12C17.75 8.82436 15.1756 6.25 12 6.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.375 22.25H13.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 3.25V1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1675 8.625L21.25 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.83253 8.625L2.75 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 4.81906L17.75 3.52002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4.82052L6.25 3.52148"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  LINK: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M9.31137 18.0957L8.74923 18.6578C7.98032 19.4268 6.93746 19.8587 5.85005 19.8587C4.76265 19.8587 3.71979 19.4268 2.95088 18.6578C2.18197 17.8889 1.75 16.8461 1.75 15.7587C1.75 14.6713 2.18197 13.6284 2.95088 12.8595L7.29964 8.50982C8.02874 7.77907 9.00664 7.35024 10.0381 7.30895C11.0695 7.26766 12.0786 7.61696 12.8637 8.28708C13.6489 8.95721 14.1524 9.89885 14.2737 10.924C14.3813 11.8333 14.1811 12.749 13.7107 13.527"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6924 5.90082L15.2512 5.34199C16.0201 4.57308 17.063 4.14111 18.1504 4.14111C19.2378 4.14111 20.2806 4.57308 21.0495 5.34199C21.8184 6.1109 22.2504 7.15376 22.2504 8.24117C22.2504 9.32857 21.8184 10.3714 21.0495 11.1403L16.7008 15.4891C15.9715 16.2195 14.9936 16.6481 13.9623 16.6891C12.9309 16.7301 11.9221 16.3807 11.1371 15.7105C10.3521 15.0403 9.84878 14.0987 9.72759 13.0737C9.61807 12.1475 9.8279 11.2147 10.3173 10.4275"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  LIST: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.08301 3.75016C2.08301 3.96903 2.12612 4.18576 2.20988 4.38797C2.29363 4.59018 2.4164 4.77391 2.57116 4.92867C2.72593 5.08344 2.90966 5.2062 3.11187 5.28996C3.31408 5.37372 3.5308 5.41683 3.74967 5.41683C3.96854 5.41683 4.18527 5.37372 4.38748 5.28996C4.58969 5.2062 4.77342 5.08344 4.92819 4.92867C5.08295 4.77391 5.20572 4.59018 5.28947 4.38797C5.37323 4.18576 5.41634 3.96903 5.41634 3.75016C5.41634 3.53129 5.37323 3.31457 5.28947 3.11236C5.20572 2.91015 5.08295 2.72642 4.92819 2.57165C4.77342 2.41689 4.58969 2.29412 4.38748 2.21036C4.18527 2.12661 3.96854 2.0835 3.74967 2.0835C3.5308 2.0835 3.31408 2.12661 3.11187 2.21036C2.90966 2.29412 2.72593 2.41689 2.57116 2.57165C2.4164 2.72642 2.29363 2.91015 2.20988 3.11236C2.12612 3.31457 2.08301 3.53129 2.08301 3.75016V3.75016Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.08301 10.0002C2.08301 10.219 2.12612 10.4358 2.20988 10.638C2.29363 10.8402 2.4164 11.0239 2.57116 11.1787C2.72593 11.3334 2.90966 11.4562 3.11187 11.54C3.31408 11.6237 3.5308 11.6668 3.74967 11.6668C3.96854 11.6668 4.18527 11.6237 4.38748 11.54C4.58969 11.4562 4.77342 11.3334 4.92819 11.1787C5.08295 11.0239 5.20572 10.8402 5.28947 10.638C5.37323 10.4358 5.41634 10.219 5.41634 10.0002C5.41634 9.78129 5.37323 9.56457 5.28947 9.36236C5.20572 9.16015 5.08295 8.97642 4.92819 8.82165C4.77342 8.66689 4.58969 8.54412 4.38748 8.46036C4.18527 8.37661 3.96854 8.3335 3.74967 8.3335C3.5308 8.3335 3.31408 8.37661 3.11187 8.46036C2.90966 8.54412 2.72593 8.66689 2.57116 8.82165C2.4164 8.97642 2.29363 9.16015 2.20988 9.36236C2.12612 9.56457 2.08301 9.78129 2.08301 10.0002V10.0002Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.08301 16.2502C2.08301 16.469 2.12612 16.6858 2.20988 16.888C2.29363 17.0902 2.4164 17.2739 2.57116 17.4287C2.72593 17.5834 2.90966 17.7062 3.11187 17.79C3.31408 17.8737 3.5308 17.9168 3.74967 17.9168C3.96854 17.9168 4.18527 17.8737 4.38748 17.79C4.58969 17.7062 4.77342 17.5834 4.92819 17.4287C5.08295 17.2739 5.20572 17.0902 5.28947 16.888C5.37323 16.6858 5.41634 16.469 5.41634 16.2502C5.41634 16.0313 5.37323 15.8146 5.28947 15.6124C5.20572 15.4101 5.08295 15.2264 4.92819 15.0717C4.77342 14.9169 4.58969 14.7941 4.38748 14.7104C4.18527 14.6266 3.96854 14.5835 3.74967 14.5835C3.5308 14.5835 3.31408 14.6266 3.11187 14.7104C2.90966 14.7941 2.72593 14.9169 2.57116 15.0717C2.4164 15.2264 2.29363 15.4101 2.20988 15.6124C2.12612 15.8146 2.08301 16.0313 2.08301 16.2502V16.2502Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91602 3.75H17.9162"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91602 10H17.9162"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91602 16.25H17.9162"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  LOGOUT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M18.5 15L21.0211 12.5364C21.3222 12.2422 21.3222 11.7578 21.0211 11.4636L18.5 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 8.25V3.5C14.5 3.08579 14.1642 2.75 13.75 2.75H3.5C3.08579 2.75 2.75 3.08579 2.75 3.5V20.5C2.75 20.9142 3.08579 21.25 3.5 21.25H13.75C14.1642 21.25 14.5 20.9142 14.5 20.5V15.7479"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.75 12L20.9989 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  MAPPIN: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9996 1.75C7.61638 1.75594 4.06454 5.30778 4.05859 9.69103C4.05859 16.3481 9.6691 21.1063 11.3954 22.0881C11.7693 22.304 12.2299 22.304 12.6038 22.0881C14.3301 21.1171 19.9407 16.3481 19.9407 9.69103C19.9347 5.30778 16.3829 1.75594 11.9996 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 6.84277C13.5729 6.84277 14.8482 8.11805 14.8482 9.69119C14.8482 11.2643 13.5729 12.5396 11.9998 12.5396C10.4266 12.5396 9.15137 11.2643 9.15137 9.69119V9.69119C9.15137 8.11805 10.4266 6.84277 11.9998 6.84277Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  MAPPINFILLED: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9996 1.75C7.61638 1.75594 4.06454 5.30779 4.05859 9.69103C4.05859 16.3481 9.66911 21.1063 11.3954 22.0881C11.7693 22.304 12.2299 22.304 12.6038 22.0881C14.3301 21.1171 19.9407 16.3481 19.9407 9.69103C19.9347 5.30779 16.3829 1.75594 11.9996 1.75Z"
        fill="#4170C4"
        stroke="#4170C4"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 6.84277C13.5729 6.84277 14.8482 8.11805 14.8482 9.69119C14.8482 11.2643 13.5729 12.5396 11.9998 12.5396C10.4266 12.5396 9.15137 11.2643 9.15137 9.69119C9.15137 8.11805 10.4266 6.84277 11.9998 6.84277Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  ),
  MINUS: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M1.75 12H22.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  NEWTAB: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12.25 2.75H3.5C3.08579 2.75 2.75 3.08579 2.75 3.5V20.5C2.75 20.9142 3.08579 21.25 3.5 21.25H20.5C20.9142 21.25 21.25 20.9142 21.25 20.5V11.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.75 2.75H20.4999C20.9142 2.75 21.2499 3.08579 21.2499 3.5V7.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7655 3.23486L11.7534 12.2469"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  OFFERSCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.7529V13.501"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.74805 8.75C7.74805 8.33579 8.08383 8 8.49805 8H15.502C15.9162 8 16.252 8.33579 16.252 8.75V12.001C16.252 12.4152 15.9162 12.751 15.502 12.751H8.49805C8.08383 12.751 7.74805 12.4152 7.74805 12.001V8.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PEN: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M15.2351 4.92354L19.0765 8.76501"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4061 3.59386C21.5313 4.719 21.5313 6.54321 20.4061 7.66835L7.81656 20.2579L2.75 21.25L3.74207 16.1834L16.3316 3.59386C17.4568 2.46871 19.281 2.46871 20.4061 3.59386Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PHONE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55009 8.13147C10.3086 7.37125 10.3086 6.14094 9.55009 5.38071L7.48639 3.31954C6.727 2.56015 5.49501 2.56015 4.73563 3.31954L3.60454 4.45146C2.62315 5.43117 2.46925 6.96675 3.23536 8.12222C6.56638 13.136 10.8645 17.4341 15.8783 20.7651C17.0337 21.5304 18.5685 21.3765 19.5499 20.3976L20.681 19.2657C21.4403 18.5063 21.4412 17.276 20.6818 16.5158L18.6181 14.4521C17.8587 13.6927 16.6284 13.6919 15.8682 14.4512L15.1794 15.14C12.8744 13.2428 10.7585 11.127 8.86219 8.82106L9.55009 8.13147Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PLAY: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M20.4942 10.6991L6.99685 2.94991C5.99686 2.37579 4.75 3.09768 4.75 4.25076V19.7491C4.75 20.9022 5.99686 21.6241 6.99685 21.05L20.4942 13.3008C21.4984 12.7243 21.4984 11.2756 20.4942 10.6991Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PLAYFILLED: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M20.4942 10.6991L6.99685 2.94991C5.99686 2.37579 4.75 3.09768 4.75 4.25076V19.7491C4.75 20.9022 5.99686 21.6241 6.99685 21.05L20.4942 13.3008C21.4984 12.7243 21.4984 11.2756 20.4942 10.6991Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PLUS: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12 2.75V21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 12H21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PLUSCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.001 7.625V16.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.625 12H16.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  PRINT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M5.74902 10.7515H6.74902"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.498 16.5H19.748C20.5765 16.5 21.248 15.8284 21.248 15V9.25C21.248 8.42157 20.5765 7.75 19.748 7.75H4.24805C3.41962 7.75 2.74805 8.42157 2.74805 9.25V15C2.74805 15.8284 3.41962 16.5 4.24805 16.5H6.49805"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M6.49805 14.25C6.49805 13.8358 6.83383 13.5 7.24805 13.5H16.7476C17.1618 13.5 17.4976 13.8358 17.4976 14.25V20.5C17.4976 20.9142 17.1618 21.25 16.7476 21.25H7.24805C6.83383 21.25 6.49805 20.9142 6.49805 20.5V14.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M6.62305 3.5C6.62305 3.08579 6.95883 2.75 7.37305 2.75H16.623C17.0373 2.75 17.373 3.08579 17.373 3.5V7C17.373 7.41421 17.0373 7.75 16.623 7.75H7.37305C6.95883 7.75 6.62305 7.41421 6.62305 7V3.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  ROTATE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M13.4629 22.1287C12.9867 22.2086 12.4975 22.2501 11.9986 22.2501C7.14639 22.2501 3.21289 18.3166 3.21289 13.4644C3.21289 8.61221 7.14639 4.67871 11.9986 4.67871C12.3286 4.67871 12.6543 4.6969 12.9748 4.73233"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.498 1.75L13.151 4.1989C13.4727 4.49587 13.4727 5.00413 13.151 5.3011L10.498 7.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4014 6.53711C18.061 7.05239 18.6448 7.65962 19.1337 8.33907"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7185 12.3901C20.7682 12.798 20.79 13.2143 20.7817 13.6371C20.7734 14.0614 20.7351 14.4782 20.6689 14.8854"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.9222 18.8721C18.408 19.5295 17.8024 20.1114 17.125 20.5991"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SAFE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12.5123 21.1547C12.1826 21.2818 11.8174 21.2818 11.4877 21.1547C8.91514 20.1675 6.70273 18.4237 5.14268 16.1534C3.58262 13.8832 2.74833 11.1933 2.75 8.43922C2.75 7.60985 2.75001 5.63789 2.75002 4.24916C2.75003 3.42074 3.4216 2.75 4.25002 2.75H19.75C20.5785 2.75 21.25 3.42075 21.25 4.24918C21.25 5.63791 21.25 7.60985 21.25 8.43922C21.2516 11.1933 20.4173 13.8832 18.8573 16.1534C17.2972 18.4237 15.0848 20.1675 12.5123 21.1547Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.47559 11.3487L9.53757 14.1281C9.80857 14.5126 10.3573 14.6157 10.7621 14.3583C10.8429 14.3064 10.9153 14.2424 10.9742 14.1689L16.5239 7.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SEARCH: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19695 3.33353C10.9081 1.75626 15.1945 3.48659 16.7717 7.19695C18.349 10.9073 16.6187 15.1945 12.9083 16.7717C9.19794 18.349 4.9108 16.6187 3.33353 12.9083C1.75626 9.19794 3.48659 4.9108 7.19695 3.33353Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4082 15.4077L21.2503 21.2498"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SHARE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M7.5 8.75H5.375C4.96079 8.75 4.625 9.08579 4.625 9.5V21.5C4.625 21.9142 4.96079 22.25 5.375 22.25H18.625C19.0392 22.25 19.375 21.9142 19.375 21.5V9.5C19.375 9.08579 19.0392 8.75 18.625 8.75H16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1.75V12.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4.49707L12.5364 1.976C12.2422 1.6749 11.7578 1.6749 11.4636 1.976L9 4.49707"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SIZE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M5.5 21.25L5.5 3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 18.5H20.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 5.18983L4.97232 2.96988C5.26537 2.67714 5.74024 2.67739 6.03298 2.97044L8.25 5.18983"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8096 15.75L21.0295 17.9723C21.3223 18.2654 21.322 18.7402 21.029 19.033L18.8096 21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 3.75H20.2488"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.249 6.25L20.249 3.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.249 12.5L20.249 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 3.75H13.9988"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SUCCESSCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.3854L9.27887 15.4571C9.57837 15.8821 10.1848 15.996 10.6322 15.7116C10.7215 15.6541 10.8015 15.5835 10.8666 15.5022L17 8.13184"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SUCCESSCIRCLEFILLED: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        fill="#999999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.63086 13.0421L8.82322 15.9973C9.11136 16.4061 9.6948 16.5157 10.1252 16.242C10.2111 16.1868 10.2881 16.1188 10.3507 16.0406L17.369 7.60693"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SWITCH: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M17.8999 9.25L20.9959 6.56677C21.341 6.26771 21.341 5.73229 20.9959 5.43323L17.8999 2.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.10093 21.25L3.0049 18.5668C2.65983 18.2677 2.65983 17.7323 3.0049 17.4332L6.10093 14.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0002 6H3.5C3.08579 6 2.75 6.33579 2.75 6.75V10.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 18H20.2502C20.6644 18 21.0002 17.6642 21.0002 17.25V13.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  TRASH: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M5.24805 5.25H18.748V20.75C18.748 21.5784 18.0765 22.25 17.248 22.25H6.74805C5.91962 22.25 5.24805 21.5784 5.24805 20.75V5.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.74805 16.25V10.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.248 16.25V10.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.74805 5.25H20.248"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.67188 4C7.67188 2.75736 8.67923 1.75 9.92188 1.75H14.075C15.3176 1.75 16.325 2.75736 16.325 4V5.25H7.67188V4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  TWITTER: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M22.25 7.1511L20.3238 6.32559L21.3328 4.12425L18.9847 4.90389C18.2368 4.20674 17.2554 3.81418 16.233 3.80322C15.1391 3.80565 14.0906 4.24129 13.3171 5.01482C12.5435 5.78836 12.1079 6.8368 12.1055 7.93074V8.84797C8.8585 9.51754 6.02427 7.74729 3.39183 4.72045C2.93322 7.16639 3.39183 9.00084 4.76767 10.2238L1.75 9.76519C1.86659 10.7367 2.32083 11.6364 3.03336 12.307C3.7459 12.9776 4.67142 13.3766 5.64821 13.4341L3.12584 14.3513C4.04306 16.1858 5.71242 16.4701 7.94128 16.6444C6.1195 17.8869 3.95462 18.5284 1.75 18.4788C13.4538 23.6795 20.3238 16.039 20.3238 9.30658V8.54528L22.25 7.1511Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  VIEW: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.12891C13.5878 9.12891 14.875 10.4161 14.875 12.0039C14.875 13.5917 13.5878 14.8789 12 14.8789C10.4122 14.8789 9.125 13.5917 9.125 12.0039C9.125 10.4161 10.4122 9.12891 12 9.12891Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9995 6.12842C7.1256 6.12842 3.13262 10.187 1.9248 11.5554C1.69706 11.8135 1.69693 12.1863 1.92448 12.4445C3.1319 13.8146 7.12516 17.8798 11.9995 17.8798C16.8738 17.8798 20.8671 13.8146 22.0745 12.4445C22.302 12.1863 22.3019 11.8135 22.0742 11.5554C20.8664 10.187 16.8734 6.12842 11.9995 6.12842Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  WARNINGCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 16.8911C11.8382 16.8911 11.707 17.0223 11.707 17.184C11.707 17.3456 11.8382 17.4768 11.9999 17.4768C12.1615 17.4768 12.2927 17.3456 12.2927 17.184C12.2927 17.0223 12.1615 16.8911 11.9999 16.8911V16.8911"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0005 13.892V6.52295"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  WHATSAPP: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12.0091 1.82868C9.30104 1.81899 6.69977 2.88424 4.77633 4.79059C2.8529 6.69695 1.76448 9.28861 1.75002 11.9967C1.74598 14.1403 2.42935 16.2287 3.69979 17.9553L2.75 21.2502L6.36024 20.4882C7.90138 21.5002 9.68674 22.0781 11.5285 22.1613C13.3704 22.2444 15.2005 21.8297 16.8266 20.9607C18.4527 20.0918 19.8145 18.8007 20.7689 17.2232C21.7232 15.6458 22.2349 13.8403 22.25 11.9967C22.2355 9.29178 21.1496 6.70287 19.2301 4.79704C17.3106 2.89121 14.714 1.82382 12.0091 1.82868Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5398 14.5021C17.476 14.3928 17.2938 14.3199 17.0296 14.1923C16.7654 14.0648 15.4442 13.4179 15.2074 13.3268C14.9705 13.2357 14.7791 13.1901 14.606 13.4635C14.4329 13.7368 14.1505 14.1286 13.9956 14.3017C13.8407 14.4748 13.6858 14.5021 13.4125 14.3655C11.8534 13.686 10.5378 12.5484 9.64048 11.1037C9.48559 10.8395 9.64048 10.6937 9.75892 10.5661C10.2054 10.1197 10.2145 10.1835 10.3238 9.96479C10.3629 9.89359 10.3834 9.81368 10.3834 9.73246C10.3834 9.65124 10.3629 9.57133 10.3238 9.50013C10.1871 9.34524 9.68603 8.05146 9.45826 7.52302C9.23048 6.99457 8.08248 6.89435 7.63604 7.38635C2.89826 12.3792 14.442 20.9346 17.394 15.723C17.5154 15.55 17.5922 15.3497 17.6176 15.1399C17.643 14.9302 17.6163 14.7173 17.5398 14.5203"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  MINUSCIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 1.5c5.799 0 10.5 4.701 10.5 10.5S17.799 22.5 12 22.5 1.5 17.799 1.5 12 6.201 1.5 12 1.5Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 12h9"
      />
    </svg>
  ),
  ADVANTAGE_CIRCLE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M1.75 12C1.75 14.7185 2.82991 17.3256 4.75216 19.2478C6.6744 21.1701 9.28153 22.25 12 22.25C14.7185 22.25 17.3256 21.1701 19.2478 19.2478C21.1701 17.3256 22.25 14.7185 22.25 12C22.25 9.28153 21.1701 6.6744 19.2478 4.75216C17.3256 2.82991 14.7185 1.75 12 1.75C9.28153 1.75 6.6744 2.82991 4.75216 4.75216C2.82991 6.6744 1.75 9.28153 1.75 12V12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9524 8.09525H17.6025C17.7431 8.09525 17.8572 8.20926 17.8572 8.3499V12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.9524L17.8571 8.09525"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16675 14.9286L9.07151 11.0238"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 13.9524L9.07153 11.0238"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  REPORT: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.75C16.6607 0.75 21.25 5.33927 21.25 11C21.25 16.6607 16.6607 21.25 11 21.25C5.33927 21.25 0.75 16.6607 0.75 11C0.75 5.33927 5.33927 0.75 11 0.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1V11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 10.9999L11 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 18L11 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ETARGET: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.75C17.6607 1.75 22.25 6.33927 22.25 12C22.25 17.6607 17.6607 22.25 12 22.25C6.33927 22.25 1.75 17.6607 1.75 12C1.75 6.33927 6.33927 1.75 12 1.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C14.2091 8 16 9.79093 16 12C16 14.2091 14.2091 16 12 16C9.79093 16 8 14.2091 8 12C8 9.79093 9.79093 8 12 8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  HOUSE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M2.6337 9.20504C2.23045 9.56216 2 10.0722 2 10.6075V19.8674C2 20.9071 2.85229 21.75 3.90365 21.75H20.0963C21.1477 21.75 22 20.9071 22 19.8674V10.7272C22 10.1797 21.759 9.65929 21.3397 9.30167L13.0227 2.20705C12.2981 1.58896 11.2222 1.59885 10.5094 2.23016L2.6337 9.20504Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.4538 11H9.54615C9.47454 10.9998 9.40358 11.0093 9.33733 11.028C9.27109 11.0466 9.21086 11.0741 9.16011 11.1089C9.10936 11.1436 9.0691 11.1849 9.04162 11.2304C9.01414 11.2758 9 11.3246 9 11.3738V15.125C9 16.1605 9.83947 17 10.875 17H13.125C14.1605 17 15 16.1605 15 15.125V11.3668C14.9973 11.2686 14.9385 11.175 14.8363 11.1064C14.7341 11.0377 14.5967 10.9995 14.4538 11Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  BROCHURE: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M21.2254 20.6731C21.2254 21.0913 21.0486 21.4924 20.7341 21.7881C20.4196 22.0838 19.9929 22.25 19.5481 22.25H4.45266C4.00782 22.25 3.5812 22.0838 3.26665 21.7881C2.95211 21.4924 2.77539 21.0913 2.77539 20.6731V3.32692C2.77539 2.90869 2.95211 2.5076 3.26665 2.21187C3.5812 1.91614 4.00782 1.75 4.45266 1.75H15.0754L21.2254 7.26923V20.6731Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0508 1.75V7.9H21.2258"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  REDO: (
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C16.5718 1.5 20.4611 4.42183 21.9025 8.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 2.5V8.5H16.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
